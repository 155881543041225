import { defineStore } from 'pinia';

// Use options store for better dev tools integration
export const useLoaderStore = defineStore('Loader', {
  state: () => ({
    $loading: false,
  }),

  getters: {
    loading: (state) => state.$loading,
  },

  actions: {
    start() {
      this.$loading = true;
    },

    stop() {
      this.$loading = false;
    },
  },
});
