import type { RouteLocationPathRaw, RouteLocationRaw } from '#vue-router';
import { useLoaderStore } from '@/store/loader';

export function go(routeRaw: RouteLocationRaw) {
  const { $sentry } = useNuxtApp();
  const loaderStore = useLoaderStore();
  const router = useRouter();
  const route = useRoute();

  if (
    route.path === routeRaw ||
    route.path === (routeRaw as RouteLocationPathRaw).path
  ) {
    return;
  }

  if ((routeRaw as RouteLocationPathRaw).query?.redirect === 'initialize') {
    routeRaw = {
      path: (routeRaw as RouteLocationPathRaw).path,
    };
  }

  loaderStore.start();
  return router
    .push(routeRaw)
    .then(() => loaderStore.stop())
    .catch((error) => {
      logger().error('go.ts', 'go()', 'Route failure', route);

      loaderStore.stop();
      $sentry.captureException(error);
    });
}
